.text-black {
  color: rgb(0, 0, 0) !important;
}
.naw_bar {
  margin-top: 80px;
}
.buttonset {
  margin-top: 10px;
}
/* Carousel.css */
.carousel {
  position: relative;
}

.slide {
  width: 100%;
  /* height: 100%; */
}

.prev,
.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px 20px;
  font-size: 24px;
  border: none;
  cursor: pointer;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}
.text_background_color {
  background-color: #fff;
}
/* ticket page */
.card_design {
  height: 225px;
}
.button-container {
  /* display: flex; */
  justify-content: center; /* Optional: Align center if needed */
}
.line {
  border-bottom: 1px solid #888;
}
.btn_class {
  background-color: #042946;
}
.align_Of_card {
  align-items: center;
}
/* .app-main .app-main__inner {
  padding: 20px 20px 0!important;
    flex: 1 1;
} */
.hover-btn-link {
  color: rgb(68, 127, 255);
}
.hover-btn-link:hover {
  color: rgb(0, 81, 255);
  font-weight: 500;
  /* font-size: 22px; */
}
.view_ticket td {
  border-top: none;
  font-size: 17.6px;
}
.scrollbar {
  overflow-y: scroll;
  overflow-x: hidden;
  /* height: 110px; */
  height: 180px;
}

.comment-paragraph {
  width: 200px; /* Set the width of the paragraph */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.imageClassAdvertising {
  background-image: url("./designUtils/Storeoverview/SolveProblemIcon/Advertising.svg");
  height: 310px !important;
  width: 100% !important;
  object-fit: cover;
  background-repeat: repeat-y;
  background-size: cover;
}
.imageClassSellerCentral {
  background-image: url("./designUtils/Storeoverview/SolveProblemIcon/SellerCentral.svg");
  height: 310px !important;
  width: 100% !important;
  object-fit: cover;
  background-repeat: repeat-y;
  background-size: cover;
}
.imageClassYourAccount {
  background-image: url("./designUtils/Storeoverview/SolveProblemIcon/YourAccount.svg");
  height: 310px !important;
  width: 100% !important;
  object-fit: cover;
  background-repeat: repeat-y;
  background-size: cover;
}
/* Initially transparent background */
.doThingscardBuild {
  border-radius: 30px;
  overflow: hidden;
  background-color: #d0efb1;
  transition: background-color 0.3s ease-in-out;
  height: 8rem;
}

.doThingscardBuild img {
  transition: transform 0.3s ease-in-out;
}

.doThingscardBuild:hover {
  background-color: #fff;
}

.doThingscardBuild:hover img {
  transform: scale(1.1);
}
.doThingscardBuild1 {
  border-radius: 30px;
  overflow: hidden;
  background-color: #fff;
  transition: background-color 0.3s ease-in-out;
  height: 10rem;
  padding:0.5rem 1.5rem;
}
.doThingscardBuild1:hover {
  background-color: #d0efb1;
}
.doThingscardsolveprob {
  border-radius: 30px;
  overflow: hidden;
  background-color: #bfaeff;
  transition: background-color 0.3s ease-in-out;
  height:8rem;
}
.viewinsights {
  border-radius: 30px;
  overflow: hidden;
  height:8rem;
  background-color: #a6fbe9;
  transition: background-color 0.3s ease-in-out;
}

.viewinsights img {
  transition: transform 0.3s ease-in-out;
}

.viewinsights:hover {
  background-color: #fff;
}

.viewinsights:hover img {
  transform: scale(1.1);
}
.viewinsights1 {
  border-radius: 30px;
  overflow: hidden;
  height: 10rem;
  background-color: #fff;
  transition: background-color 0.3s ease-in-out;
  padding: 0.5rem 1.5rem
}
.viewinsights1:hover {
  background-color: #a6fbe9;
}
.doThingscardsolveprob {
  border-radius: 30px;
  overflow: hidden;
  height:8rem;
  background-color: #bfaeff;
  transition: background-color 0.3s ease-in-out;
}

.doThingscardsolveprob img {
  transition: transform 0.3s ease-in-out;
  /* padding-top: 11px;
  padding-bottom: 16px; */
}

.doThingscardsolveprob:hover {
  background-color: #fff;
}

.doThingscardsolveprob:hover img {
  transform: scale(1.1);
}
.doThingscardsolveprob1 {
  border-radius: 30px;
  overflow: hidden;
  background-color: #fff;
  height: 10rem;
  padding:0.5rem 1.5rem;
  transition: background-color 0.3s ease-in-out; /* Adding transition for background color change */
}
.doThingscardsolveprob1:hover {
  background-color: #bfaeff; /* Change to #D0EFB1 on hover */
}

.doThingscardlaunch {
  border-radius: 30px;
  overflow: hidden;
  height:10rem;
  margin-bottom: 10px;
  background-color: #ffddcc;
  transition: background-color 0.3s ease-in-out; /* Adding transition for background color change */
  height:8rem;
}

.doThingscardlaunch img {
  transition: transform 0.3s ease-in-out; /* Adding a transition effect */
}

.doThingscardlaunch:hover {
  background-color: #fff; /* Change to #D0EFB1 on hover */
}

.doThingscardlaunch:hover img {
  transform: scale(1.1); /* Example: Enlarge the image on hover */
}
.doThingscardlaunch1 {
  border-radius: 30px;
  overflow: hidden;
  height: 10rem;
  padding:0.5rem 1.5rem;
  background-color: #fff;
  transition: background-color 0.3s ease-in-out; /* Adding transition for background color change */
}
.doThingscardlaunch1:hover {
  background-color: #ffddcc; /* Change to #D0EFB1 on hover */
}

/*  Side bar */

.app_ {
  display: flex;
}
/* .sidebar {
  width: 100% !important;
  height: 100%;
  background-color: white;
  color: #333;
  padding: 20px;
  transition: all 0.3s ease;
  box-shadow: 3px 3px 7px #e2e7e7;
} */
.sidebar {
  width: 100% !important;
  height: 525px;
  background-color: #b1fa63 ;
  color: #333;
  padding: 9px;
  border-radius: 50px; 
  border-right: 1px solid rgb(218, 214, 214); 
  transition: all 0.3s ease; 
  box-shadow: 3px 3px 7px #e2e7e7;
}
.sidebar2 {
  width: 530px !important;
  height: 100%;
  background-color: white;
  color: #333;
  padding: 20px;
  transition: all 0.3s ease;
}
.sidebar.closed_ {
  width: 0;
  overflow: hidden !important;
}
.closed_ {
  width: 0;
  overflow: hidden !important;
}
.open_ {
  width: 0;
  overflow: visible !important;
}

.content {
  flex-grow: 1;
  padding: 20px;
}

.toggle-btn {
  background: none;
  border: none;
  color: #333;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 10px;
}
.sub-menu {
  margin-top: 5px;
  padding-left: 45px;
  list-style: none;
  font-weight: 400;
  transition: all 0.3s ease;
}
.sub-menu li {
  margin-bottom: 10px !important;
  font-size: 12px;
}
.sub-menu.closed {
  max-height: 0;
  overflow: hidden;
}
.collapsible-icon {
  float: right;
}

.selected {
  /* background-color: #4caf50;  */
  margin:0px !important ;
  color: black;
  /* font-weight: 900; */
}
.sidebar ul li {
  color: #243837 !important;
  font-size: 12px;
  margin-bottom: 20px;
  cursor: pointer;
}

.sidebar2 li {
  color: #243837 !important;
  font-size: 14px;
  margin-bottom: 20px;
  cursor: pointer;
}
.sidebar-icon {
  color: #6a7a7a;
  width: "20px";
  height: "20px";
  font-size: 20px;
}
@media (min-width: 1200px) {
  .sidebar {
    width: 82px !important;
  }
}

/* Medium Screens (Tablets) */
@media (max-width: 992px) {
  .sidebar {
    width: 200px !important;
    height: 100vh; /* Full height */
    border-radius: 30px; /* Adjusted for better appearance */
  }
}

/* Small Screens (Mobile Devices) */
@media (max-width: 768px) {
  .sidebar {
    width: 60px !important; /* Minimal sidebar for icons */
    height: 100vh;
    padding: 5px;
    border-radius: 20px;
  }
}

/* Extra Small Screens (Phones) */
@media (max-width: 480px) {
  .sidebar {
    width: 50px !important;
  }
}
.selected-icon {
  color: black;
  width: "20px";
  height: "20px";
  font-size: 20px;
}
.fw-500 {
  font-weight: 500;
  font-size: 12px;
  margin-left: 30px;
}
/* ------------------  */
.problemArea {
  overflow: hidden;
  transition: background-color 0.3s ease-in-out;
  background-color: #bfaeff;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}
.problemArea:hover img {
  transform: scale(1.1);
}
.problemArea1 {
  overflow: hidden;
  background-color: #fff;
  transition: background-color 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}
.problemArea1:hover {
  background-color: #bfaeff;
}
.card-of-problemarea {
  border-radius: 20px !important;
}
.buildBrandsVisit {
  overflow: hidden;
  background-color: #fff;
  transition: background-color 0.3s ease-in-out;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.buildBrandsVisit img {
  transition: transform 0.3s ease-in-out;
}

.buildBrandsVisit:hover {
  background-color: #d0efb1;
}

.buildBrandsVisit:hover img {
  transform: scale(1.1);
}
.buildBrandsVisit1 {
  overflow: hidden;
  border-radius: 30px;
  padding: 25px;
  background-color: #d0efb1;
  transition: background-color 0.3s ease-in-out;
}
.buildBrandsVisit2 {
  overflow: hidden;
  background-color: #d0efb1;
  transition: background-color 0.3s ease-in-out;
}

/* Login page */
.loginPage .bg-img {
  background-image: url("./designUtils/Logo/Entry/LoginPageBg.png");
  height: 310px !important;
  width: 100% !important;
  object-fit: cover;
  background-repeat: repeat-y;
  background-size: cover;
}
.loginPage .login-btn {
  background-color: #b1fa63;
  margin-top: 10px;
  border: none;
  color: black;
}
.loginPage .text-color {
  color: #488a02;
}
.bgColor .modal-body {
  background-color: #bfaeff;
}
/* end Login page */
/* problempathways subtypes */
.subtypesOfPoblemPathway:hover {
  overflow: hidden;
  transition: background-color 0.3s ease-in-out;
  background-color: #bfaeff;
}
/* report screen  */
.reportImgBackground {
  background-color: #fe7733;
  border-radius: 20px;
}
.reportTextBackground:hover {
  overflow: hidden;
  transition: background-color 0.3s ease-in-out;
  background-color: #fe7733;
  border-radius: 30px;
}
.bgColor {
  border-radius: 30px !important;
  /* border-top-right-radius: 30px!important;
  border-bottom-left-radius: 30px!important;
  border-bottom-right-radius: 30px!important; */
}
.bgColor .modal-content {
  border-radius: 30px !important;
  /* border-top-right-radius: 30px!important;
  border-bottom-left-radius: 30px!important;
  border-bottom-right-radius: 30px!important; */
}
.bgColor .modal-header {
  border-top-left-radius: 30px !important;
  border-top-right-radius: 30px !important;
}
.bgColor .modal-body {
  border-bottom-left-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}
.bgColor .modal-footer {
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}
.custom-file-input-label {
  color: #3498db;
  cursor: pointer;
  border: 1px solid #3498db;
  border-radius: 5px;
  align-items: center;
}
.custom-file-input {
  display: none;
}
.back-btn {
  /* border-radius: 50px; */
  border: none !important;
  color: #707070;
  background-color: transparent;
}
/* css for status ops dash */

.Open-button {
  background-color: #be0000;
  padding-left: 8px;
  padding-right: 14px;
  border-radius: 10px;
}
.closed-button {
  background-color: #363636;
  padding-right: 14px;
  border-radius: 10px;
}
.amazon-ticket-raised{
  background-color: #FFB800;
  padding-right: 14px;
  border-radius: 10px;
}
.amazon-response-received {
  background-color: #008A79;
  padding-right: 10px;
  border-radius: 10px;
}
.working-with-amazon
{
  background-color: #008A79;
  padding-right: 10px;
  border-radius: 10px;
}
.Resolved-button {
  background-color: #0e7300;
  padding-right: 8px;
  border-radius: 10px;
}
.Needinfo-button {
  background-color: #0170c0;
  padding-right: 14px;
  border-radius: 10px;
}
.img-class {
  background-color: white;
  margin: 8px;
  border-radius: 20px;
}
.status-text {
  margin: 5px !important;
}
.Inprogress-button {
  background-color: #5cc71a;
  padding-right: 14px;
  border-radius: 10px;
}
.Ticketupdat-button {
  background-color: #9e00ff;
  padding-right: 11px;
  border-radius: 10px;
}
.Pending-button {
  background-color: #c76d14;
  padding-right: 10px;
  border-radius: 10px;
}

.Assigned-button {
  background-color: #d7008a;
  padding-right: 11px;
  border-radius: 10px;
}
/* status button end */
.button-ticket {
  background-color: #d0efb1;
  color: black;
  transition: transform 0.3s ease;
}

.button-ticket:hover {
  transform: scale(1.1);
  color: black;
  background-color: #d0efb1;
}
/* New Product Page */
.parent-card {
  border-radius: 15px;
}
.child-card {
  border-radius: 15px;
}
.selected-parent-card {
  background-color: #dfd7ff;
}
.selected-child-card {
  background-color: #d8fcb1;
}
.toggle-icon {
  background: transparent;
  border: none;
  cursor: pointer;
}
/* product screen  */
.card-product {
  transition: transform 0.3s ease;
}

.card-product:hover {
  transform: scale(0.98);
}
.green-btn {
  background-color: #b1fa63;
  border: none;
  color: #000 !important;
  padding: 8px;
  font-weight: 500;
  border-radius: 10px;
}
.detail-card {
  border-radius: 10px;
}
.rightside-card {
  background-color: #d8fcb1;
  height: fit-content;
}
.campaign-div {
  /* margin-top: 15px; */
  background-image: linear-gradient(180deg, #2c0434, #23055e);
  padding: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

/* Original styling for the button */
.campaign-btn {
  padding: 0.6em 2em;
  font-weight: 500 !important;
  border: none;
  outline: none;
  color: #fff !important;
  background: #533fd1 !important;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

/* Style for the button after hovering */
.campaign-btn:hover:before {
  content: "";
  font-weight: 500 !important;
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  animation: glowing-campaign-btn 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

/* Animation keyframes for the button after hovering */
@keyframes glowing-campaign-btn {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 200% 0;
  }
  100% {
    background-position: 0 0;
  }
}

/* Style for the button after hovering */
.campaign-btn:hover:after {
  background: #533fd1 !important;
  font-weight: 500 !important;
}

/* Additional styling for the button after hovering */
.campaign-btn:after {
  z-index: -1;
  font-weight: 500 !important;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #533fd1 !important;
  left: 0;
  top: 0;
  border-radius: 10px;
}
.btn-product {
  border: none;
  background: none;
}
.btn-background-color {
  background-color: #b1fa63 !important;
  color: #243837;
}
.padding-2 {
  padding-left: 2.5rem;
}
/* UserProfile css */
.page-header {
  position: fixed;
  top: 4.2rem;
  z-index: 1000;
  height: 5rem;
  width: 81%;
}
/* Side bar  */
#main-user-profile {
  margin-top: 50px;
}
#main-user-profile .sub-options {
  margin-left: 10px;
  list-style: none;
  padding-left: 0.8rem;
}
#main-user-profile .mb-20 {
  margin-bottom: 20px;
}
#main-user-profile .right-border {
  /* border-right: 1px solid rgb(201, 199, 199); */
  position: sticky;
  top: 5rem;
  height: fit-content;
}

#main-user-profile .right-b {
  border-right: 1px solid rgb(201, 199, 199);
  height: 80vh;
}

#main-user-profile .sidebar-icon {
  font-size: 16px;
  margin-right: 5px;
}
#main-user-profile .list-color {
  color: rgb(129, 128, 128);
}
#main-user-profile .list-color:hover .sidebar-icon {
  font-weight: 500 !important;
  color: #000 !important;
}
#main-user-profile .list-color:hover {
  font-weight: 500 !important;
  color: #000 !important;
}
#main-user-profile .selected-list-option {
  font-weight: 500 !important;
  color: #000 !important;
}
/* end sidebar css */

.thick-line {
  border-top: 0.5px solid rgb(153, 151, 151);
  margin-bottom: 20px;
}

.top-right-image {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 10px;
}
#user-profile {
  border-left: #b1fa63 solid 5px;
  height: 50px !important;
  margin-bottom: 10px;
}
#user-profile .heading {
  font-size: 30px;
  margin-top: 10px;
}
#user-profile .heading:hover {
  margin-top: -8px !important;
}

#user-profile .heading-text:hover {
  border-bottom: #b1fa63 solid 2px;
}
#user-profile .edit-icon {
  font-size: 30px;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 10px;
}

#user-profile .edit-icon:hover {
  color: #b1fa63;
}

#user-profile div:hover {
  color: #b1fa63;
}

.user-profile-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  margin-left: 15px;
}

.user-profile-label,
.user-profile-content {
  padding: 8px;
  text-align: left;
  font-size: 16px;
}

.user-profile-label {
  font-size: 17px;
  font-weight: bold;
  padding-left: 10px;
  width: 300px;
}

.input-container {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 15px;
  padding-left: 80px;
  padding-right: 80px;
}
.input-box {
  border-left: none !important;
}
.img-with-background {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 5px;
  background-color: #b1fa63;
}

.user-profile-btn {
  background-color: #b1fa63;
  border: none;
  font-size: 20px;
  color: #000;
  font-weight: 500;
  width: 200px;
  height: 50px;
}
.save-btn {
  align-items: center !important;
}
.custom-header .custom-heading {
  border-left: 5px solid #b1fa63;
  padding-left: 10px;
}
tr {
  border-bottom: 1px solid rgb(150, 148, 148);
}

.hidden-checkbox {
  display: none;
}

.custom-label {
  font-size: 16px;
}
.authorize-button {
  background-color: #b1fa63;
  border: none;
  color: #000 !important;
}
.validation-text {
  color: red;
  margin-left: 10px;
}

@media only screen and (min-width: 1439px) and (max-width: 1700px) {
  .page-header {
    width: 81%;
  }
}

@media only screen and (min-width: 1700px) and (max-width: 1920) {
  .page-header {
    width: 90%;
  }
}
/* ops table count  */
.open-count {
  color: #be0000;
}
.NeedInfo-count {
  color: #0170c0;
}
.Assigned-count {
  color: #d7008a;
}
.Updated-count {
  color: #243837;
}
.Resolved-count {
  color: #0e7300;
}
.Closed-count {
  color: #363636;
}
.New-count {
  color: #9b0d00;
}
.InProgress-count {
  color: #c76d14;
}
.circle {
  width: 30px;
  height: 30px;
  padding-right: 5px;
  padding-top: 5px;
  padding-left: 7px;
  color: white !important;
}
.ticket-age-box {
  padding: 9px !important;
  /* padding-right: 10px !important; */
  width: 90px !important;
  height: 37px !important;
  margin-left: 62px !important;
}
.curve-popup {
  border-radius: 30px !important;
}
.curve-popup .modal-content {
  border-radius: 30px !important;
}
.curve-popup .modal-header {
  border-top-left-radius: 30px !important;
  border-top-right-radius: 30px !important;
}
.curve-popup .modal-body {
  border-bottom-left-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}
.curve-popup .modal-footer {
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

/* ---------Questionnaire------------ */

#questionnaire .card-min-height {
  min-height: 450px;
}
#questionnaire .prev-next {
  bottom: 0px;
  position: absolute;
}
#questionnaire .custom-brand-file-input {
  color: #3498db;
  cursor: pointer;
  border: 1px dashed #ccd5db;
  border-radius: 20px;
  align-items: center;
  padding-right: 16px;
  padding-left: 6px;
  font-size: 20px;
  padding-top: 7px;
  padding-bottom: 15px;
}

#questionnaire .custom-brand-file {
  display: none;
}
/* ProgressBar.css */

.brand-progressBar {
  height: 20px;
  background-color: #b1fa63;
  transition: width 0.3s ease-in-out;
  border-top-left-radius: 10px;
  /* border-top-right-radius:10px;  */
}
.brand-progressBar-outer {
  background-color: #ffddcc;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
/* color palatte */
.color-palet {
  height: 80px;
  width: 70px;
  border: none;
}
.color-palate-text {
  width: 60px;
  height: 10px;
}
.color-palate-button {
  border: none;
  background-color: aqua;
  border-radius: 20px;
}

.carousel-container {
  overflow: hidden;
  width: 100%;
  max-width: 600px; /* Adjust the maximum width as needed */
  margin: 0 auto;
}

.carousel-slide {
  display: flex;
  transition: transform 1s ease-in-out;
}

.carousel-image {
  width: 100%;
  height: auto;
}

.container-color-palette {
  display: flex;
  width: 20vw;
  /* margin: 80px auto; */
  gap: 20px;
  min-height: 20vh;
}

.slide {
  display: flex;
  /* flex: 2; */
  /* position: relative; */
  cursor: pointer;
  transition: flex 0.2s ease-in-out;
  /* border-radius: 20px; */
}

.slide > input {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 40px;
  transition: flex 0.2s ease-in-out;
  animation-duration: 0s;
  animation-delay: 0.7s;
}
/*  */

/* .active {
  flex: 5;
} */
.in-active {
  width: 30px;
  /* animation-duration: 0.2s;
  animation-delay: 0.7s; */
  /* transition: flex 0.2s ease-in-out; */
}
.text-box-color {
  border: none;
  height: 80px;
  color: #00ffd5;
  /* transition: flex 0.2s ease-in-out; */
}
.width-50 {
  /* animation-duration: 0.2s;
  animation-delay: 0.2s; */
  /* transition: flex 0.2s ease-in-out; */
  border-radius: 10px;
  width: 80px !important;
}
.brand-card-background1 :hover {
  background-color: #d0efb1;
  border-radius: 25px;
}
.child-card .trans :hover {
  transform: scale(1.1);
  width: 40px;
  height: 15px;
}
.progress-bar-curve {
  border-top-right-radius: 10px !important;
}
.uploadfile {
  cursor: pointer;
  border: 1px dashed #ccd5bd;
  border-radius: 10px;
  display: block;
  padding: 20px;
  position: relative;
}
.selected-tab {
  background-color: #d0efb1 !important;
  border-radius: 10px;
  padding: 5px 0px 5px 0px !important;
}
.uploadedfile-card1 .modal-body {
  background-color: #d0efb1;
}

/* ToggleSwitch.css */

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 28px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.dot {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.dot:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 7px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

.toggle-switch input:checked + .dot {
  background-color: #33f321;
}

.toggle-switch input:checked + .dot:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.btn-background-color-cancle {
  background-color: #ffffff;
  color: #243837;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.btn-background-color-cancle:hover {
  background-color: #e8e2ff;
  color: #141414;
}

.btn-background-color-cancle.active {
  background-color: #e8e2ff;
}

.color-box {
  display: inline-block;
  height: 20px;
  width: 20px;
  margin-right: 5px;
}
.inner-card-question {
  border-radius: 20px;
}
.ans-background {
  background: linear-gradient(0.25turn, rgb(139, 255, 255), rgb(255, 255, 255));
}
.product-button-color :hover {
  background-color: #dfd7ff !important;
}
.product-button-color-inner {
  background-color: #dfd7ff !important;
}
/* Asset Gallery css */
.assets-button-switch {
  background-color: #ffffff;
  border: none;
  color: #243837;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}
.assets-button-switch:hover {
  background-color: #d0efb1 !important;
  color: #141414;
}
.assets-button-switch:active {
  background-color: #d0efb1;
}

.assets-button-shareByu {
  background-color: #ffffff;
  border: none;
  color: #243837;
  padding: 8px 16px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.assets-button-shareByu:hover {
  background-color: #d0efb1;
  color: #141414;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.assets-button-shareByu.active {
  background-color: #d0efb1;
}
.assets-button-shareByus {
  background-color: #ffffff;
  border: none;
  color: #243837;
  padding: 8px 16px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.assets-button-shareByus:hover {
  background-color: #d0efb1;
  color: #141414;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.assets-button-shareByus.active {
  background-color: #d0efb1;
}
.info-button-background-none {
  background-color: transparent;
  border: none;
}
.info-button-background-none:hover {
  background-color: transparent;
  border: none;
}
.info-button-background-none:active {
  background-color: transparent;
  border: none;
}
/* img card */
.showcase {
  width: 100%;
  height: 500px;
  position: relative;
  color: white;
  text-align: center;
}

.showcase img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
}

.showcase .overlay {
  width: 100%;
  height: 500px;
  background-color: rgba(0, 35, 82, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}

.showcase h2 {
  margin-top: 170px;
  font-size: 3em;
}

.showcase p {
  margin-top: 8px;
  font-size: 1.2em;
}
.info-button-background-none.disabled,
.info-button-background-none:disabled {
  background-color: transparent !important;
}
.button-img-background-assets:hover {
  background-color: #d0efb1;
  border-radius: 10px;
}
.delete-button-background-none {
  background-color: #fff;
  color: red;
}
.delete-button-background-none:hover {
  background-color: #e72424;
  border: none;
}
.delete-button-background-none:active {
  background-color: #e72424;
  border: none;
}
.asset-carousel {
  /* opacity: 99%; */
  background: none;
  box-shadow: none;
}
.asset-carousel .modal-content {
  background: none;
  border: none;
}
.asset-carousel .modal-header {
  display: none;
}
.asset-carousel .modal-body {
  background: none;
}
.asset-carousel .modal-footer {
  background: none;
}
.file-view-div {
  display: flex;
  height: 400px;
  flex-direction: column;
  align-items: center;
}
.file-view {
  min-width: 300px;
  max-width: 1000px;
  min-height: 300px;
  max-height: 420px;
}
.modal {
  background: #2f4645e8;
}
.delete-btn-popup {
  position: relative;
  bottom: 50px;
}
.prev-next {
  font-size: 50px;
  background: transparent !important;
  border: none !important;
}
.btn-background-color-pathways {
  background-color: #d0efb1 !important;
  color: #243837;
}
.btn-background-color-Proceed-gallery {
  background-color: #b1fa63 !important;
  color: #000;
}

.fixed-height {
  min-height: 144px;
}
.button-img-background-download:hover {
  background-color: #e8e2ff;
  border-radius: 10px;
}
.button-img-background-download-inprogress {
  background-color: #FD9808;
  border-radius: 10px;
}
.dotted-box-upload-product {
  border: 1px dashed #ccd5db;
  padding: 10px;
  border-radius: 10px;
}
.box-border-upload-product {
  border: 1px solid #ab979757;
  padding: 10px;
  border-radius: 10px;
}
.position-of-pop-up {
  position: absolute;
  width: 50%;
  bottom: 0rem;
  max-height: 600px;
  right: 0rem;
  margin-right: 25px;
}
.chat-background-color {
  background-color: #ffddcc;
  border-radius: 15px;
}
.chat-background-color-sent {
  border-radius: 15px;
  background-color: #8be9a7;
}
.btn-background-color-comments {
  background-color: #b1fa63 !important;
  /* padding: 8px; */
  margin-left: 7px;
  border-radius: 22px;
  border: none;
}

.user-short-name {
  border: 2px solid rgb(97, 95, 95);
  border-radius: 50px;
  font-weight: 700;
  font-size: 18px;
  margin-right: 5px;
}

/* CustomScrollbar.css */
.scrollable-div {
  /* max-height: 300px; Set your desired maximum height */
  overflow-y: auto; /* Enable vertical scrolling */
  scroll-behavior: smooth; /* Enable smooth scrolling */
  scroll-snap-type: y mandatory; /* Enable snapping to ensure smooth scrolling behavior */
}
.scrollable-div::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

.scrollable-div::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the scrollbar track */
}

.scrollable-div::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 5px; /* Rounded corners */
}

.scrollable-div::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar thumb on hover */
}

/* .scrollable-div > *:last-child {
  scroll-snap-align: end;
} */

.comments-bg-color:hover {
  background-color: yellow;
  border-radius: 20px;
}
.carousel-img-background-none {
  background-color: #fff;
  border-radius: 10px;
}
.carousel-img-background-none:hover {
  background-color: #d0efb1;
  border-radius: 10px;
}
.carousel-img-background-none-download {
  background-color: #fff;
  border-radius: 10px;
}

.carousel-img-background-none-download:hover {
  background-color: #e8e2ff;
  border-radius: 10px;
}
.comments-bg-color:hover {
  background-color: yellow;
  border-radius: 20px;
}
.carousel-img-background-none {
  background-color: #fff;
  border-radius: 10px;
}
.carousel-img-background-none:hover {
  background-color: #d0efb1;
  border-radius: 10px;
}
.carousel-img-background-none-download {
  background-color: #fff;
  border-radius: 10px;
}

.carousel-img-background-none-download:hover {
  background-color: #e8e2ff;
  border-radius: 10px;
}
.selected-tab-workflows-previewmode{
  border-radius: 10px;
  /* padding: 15px 10px 10px 10px !important; */
}
.selected-tab-workflows {
  background-color: #d0efb1 !important;
  border-radius: 10px;
  padding: 15px 10px 10px 10px !important;
}
.card-min-height-workflows {
  min-height: 400px;
}
/* background color of workflows dropdown */
.inprogress-dropdown {
  background-color: #f8ffad !important;
}
.completed-dropdown {
  background-color: #D0EFB1 !important;
}
.notstarted-dropdown {
  background-color: #E8E2FF !important;
}
.notapplicable-dropdown {
  background-color: #BDBCBC !important;
}
.postpond-dropdown {
  background-color: #FFDDCC !important;
}
.signedoff-dropdown {
  background-color: #57b24b !important;
  padding: 7px 20px 7px 25px;
  border-radius: 5px;
  color : #fff
}

.internal-ticket-checkbox{
    padding: 5px 7px;
    background: #f7ddee;
    border-radius: 10px;
    font-style: italic;
  }

.accept-checkbox{
    padding: 5px 7px;
    background:#B1FA63;
    border-radius: 4px;
    font-size: 16px;
  }

.card-min-height-additional{
  min-height: 400px;
  border-radius: 20px;

}
.col-containt-fit-additional{
  margin-top: 75px;
}
.comment-paragraph-workflows{
  width: 200px; /* Set the width of the paragraph */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.storeHealth-report-fetch {
  background-color: #d0efb1 !important;
  border-radius: 10px;
  padding: 10px !important;
  cursor: pointer;
  margin: 10px;
}
.storeHealth-report-fetch1 {
  /* background-color: #d0efb1 !important; */
  border-radius: 10px;
  padding: 10px !important;
  cursor: pointer;
  margin: 10px;
}
.cursor-pointer
{
  color:#0170c0;
  cursor: pointer!important;
}
.custom-checkbox {
  width: 12px; 
  height: 12px; 
  transform: scale(1.5); 
  margin: 5px; 
}
.ql-container{
  min-height: 150px;
}
.issue-discription-set{
  overflow-y: auto;
}
/* .issue-discription-set{
  height: 200px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  

   
} */
.custom-issue-dis-box{
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  padding: 1rem;
}
.custom-issue-dis-box-user{
  border: 1px solid #33b918;
  border-radius: 0.5rem;
  padding: 1rem;
}
.comment-paragraph-ticket-history {
  width: 400px; /* Set the width of the paragraph */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selected-tab-assets-tab {
  background-color: #d0efb1 !important;
  border-radius: 10px;
  /* margin-right: 25px;
  margin-left: 10px; */
  cursor: pointer;
  padding: 10px;
}
.assets-tab-not-selected{
  /* margin-right: 25px;
  margin-left: 10px; */
  cursor: pointer;
  padding: 10px;
}
.xlx-file {
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  height: 100%; /* Optional: ensure the div has a defined height */
}
 
.store-list-height {
  /* max-height: 300px; Adjust as needed */
  overflow-y: scroll; /* Enable vertical scrolling */
  overflow-x: hidden;
  height: 150px;
}

/* Style the scrollbar container */
.store-list-height::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

/* Style the scrollbar track (the part behind the thumb) */
.store-list-height::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track color */
  border-radius: 10px; /* Rounded corners */
}

/* Style the scrollbar thumb (the draggable part) */
.store-list-height::-webkit-scrollbar-thumb {
  background: #888; /* Thumb color */
  border-radius: 10px; /* Rounded corners */
}

/* Change color on hover */
.store-list-height::-webkit-scrollbar-thumb:hover {
  background: #555; /* Thumb color on hover */
}

/* For Firefox */
.store-list-height {
  scrollbar-color: #243837 #f5f5f5; /* Thumb color and track color */
  scrollbar-width: thin; /* Scrollbar width */
}

.store-boreder {
  border: 2px solid #B1BABF; 
  border-radius: 5px;      
}

.selected-store-color {
  background-color: #b1fa63 !important;
  font-weight: 650;
  position: sticky;
  cursor: pointer;
  top: 0; 
  bottom: 0; /*Adjust this value based on where you want it to "stick" */ 
  z-index: 1; /* Ensures the sticky element stays on top if there are overlapping elements */
}
.not-selected-store-color{
  cursor: pointer;
}
.amazon-live-images
{
  width: 180px!important;
  height: 180px!important;
}

/* Store Overview version 1 */
.store-overview-bar-icons{
 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 8px;
}
.store-overview-bar-icons-selected{
  padding: 8px;
  background-color: white;
  border-radius: 22px !important;
 
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 5px 5px 5px #a7d37c inset, -5px -5px 5px #c3ef94 inset;
  /* box-shadow: 5px 5px 5px #8eb767 inset, -5px -5px 5px #bbe58f inset; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.neumorphism-container-selected{
  display: flex;
  justify-content: center;
  align-items: center;

  /* padding: 9px;
  border-radius: 40px; */
  /* background-color: #ffffff; */
}
.neumorphism-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  padding: 9px;
  border-radius: 40px;
  /* background-color: #ffffff; Light gray background for neumorphic effect */
}
.container {
  max-width: 98% !important;
}





/* .store-list-height{
  overflow-y: scroll;
  overflow-x: hidden;
  height: 150px;

} */
/* For WebKit browsers (Chrome, Safari, Edge) */
.store-list-height {
  /* max-height: 300px; Adjust as needed */
  overflow-y: scroll; /* Enable vertical scrolling */
  overflow-x: hidden;
  height: 150px;
}

/* Style the scrollbar container */
.store-list-height::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

/* Style the scrollbar track (the part behind the thumb) */
.store-list-height::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track color */
  border-radius: 10px; /* Rounded corners */
}

/* Style the scrollbar thumb (the draggable part) */
.store-list-height::-webkit-scrollbar-thumb {
  background: #888; /* Thumb color */
  border-radius: 10px; /* Rounded corners */
}

/* Change color on hover */
.store-list-height::-webkit-scrollbar-thumb:hover {
  background: #555; /* Thumb color on hover */
}

/* For Firefox */
.store-list-height {
  scrollbar-color: #b1fa63 #f5f5f5; /* Thumb color and track color */
  scrollbar-width: thin; /* Scrollbar width */
}
.selected-store-color{
  color: #b1fa63;
}
.sidebar-margin{
  margin-top: 90px;
}
.card_design-homepage{
height: 12.5rem;
padding: 0.7rem 2rem !important;
}
.font-style-storeoverview{
 font-size: 11px;
}
.product-card-width
{
  width: 100%!important;
}
.selected-report{
  background-color: #fe7733;
  color: #fff;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer!important;
}
.not-selected-report{
  padding: 10px;
  cursor: pointer!important;
}
.store-overview-icontext{
  margin-left: 11px;
}

.selected-store-color{
  background-color: #b1fa63 !important;
  /* padding: 3px; */
  font-weight: 650;
  /* border-radius: 6px; */
}

.selected-my-tasks{
  background-color: #d0efb1;
  padding: 9px;
  border-radius: 10px;
  cursor: pointer;
}
.not-seletcted-tasks{
  padding: 9px;
  border-radius: 10px;
  cursor: pointer;
}
.tasklist-scrolle{
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
  display: block;
  
}

.custom-scroll-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed; /* This ensures the columns have a fixed layout */
}

.custom-scroll-table thead {
  background-color: #f8f9fa; /* Adjust color as needed */
  display: table; /* Ensure thead behaves like a table */
  width: 100%;
}

.custom-scroll-table thead th {
  position: sticky;
  top: 0;
  background-color: #f8f9fa; /* Keeps the header background color */
  padding: 10px;
  border: 1px solid #dee2e6;
  z-index: 1;
  width: 12.5%;
  /* width: 20%;  */
}

.custom-scroll-table tbody {
  /* display: block; */
  height: 400px; /* Adjust height as needed */
  /* overflow-y: auto; */
  width: 100%;
}

.custom-scroll-table tbody td {
  padding: 10px;
  border: 1px solid #dee2e6;
  /* width: 20%;  */
  width: 12.5%;
}

.custom-scroll-table.table thead th, .table td{
  width: 5.17rem;
}

.custom-scroll-table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

@media screen and (max-width: 1024px) {
  .custom-scroll-table thead th, .custom-scroll-table tbody td {
    /* font-size: 13px;  */
    padding: 8px;
    /* max-height: 800px; Reduce padding */
  }
} 

/* For larger screens like desktops */
@media screen and (min-width: 1025px) {
  .custom-scroll-table thead th, .custom-scroll-table tbody td {
    /* font-size: 13px; */
    padding: 8px;
    /* max-height: 1000px; */
     /* Keep standard padding */
  }
}



/* .tasklist-scrolle {
  overflow-y: auto;
} */

/* Other Card css */
.other-bgColor {
  border-radius: 30px !important;
  /* border-top-right-radius: 30px!important;
  border-bottom-left-radius: 30px!important;
  border-bottom-right-radius: 30px!important; */
}
.other-bgColor .modal-content {
  border-radius: 30px !important;
  /* border-top-right-radius: 30px!important;
  border-bottom-left-radius: 30px!important;
  border-bottom-right-radius: 30px!important; */
}
.other-bgColor .modal-header {
  border-top-left-radius: 30px !important;
  border-top-right-radius: 30px !important;
}
.other-bgColor .modal-body {
  border-bottom-left-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}
.other-bgColor .modal-footer {
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}
.other-bgColor .modal-body {
  background-color: #d0efb1;
}

/* Base style for list items */
.store-item {
  /* padding: 10px; */
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease; 
}

/* Hover effect */
.store-item:hover {
  color: #b1fa63 !important; 
  /* color: #fff; */
  /* padding: 3px; */
  border-radius: 6px;
}
.store-boreder {
  border: 2px solid #B1BABF; 
  border-radius: 5px;      
}
.help-text-workflows{
  background-color: #FFDACE;
  padding: 5px;
  border-radius: 10px;
  /* align-items: center; */
}
.issue-discription-set p {
  margin: 0px; 
}
.issue-discription-set img{
  padding-bottom: 0px;
}
.issue-discription-set p:has(img) {
  height: 100px; 
  display: flex;
}
.issue-discription-set img {
  margin-left: 10px;
}
.issue-discription-set p:has(img:hover) {
  height: 100%; 
  display: grid;
  cursor: pointer;

}
.latest-comment-files{
  width: 525px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ql-editor p:has(img)  {
  max-width: 30%;
  height: auto;
  margin-bottom: 0px;
}

.assets-card-sticky{
  position: sticky;
  top: 20px; /* Adjust the top offset as needed */
  /* z-index: 1000; Ensure it stays on top of other elements */
  background-color: #fff; /* Add a background color for better visibility */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
}
.product-name {
  max-width: 930px; /* Adjust based on other content */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.position-relative {
  position: relative;
  display: inline-block; /* Ensures proper alignment */
}

.border-assets-subtypes{
  border: 2px solid #b1fa63; 
}
.Assets-Counts {

  background-color: #FE7733 !important; /* Red background */
  color:  #FFFFFF; /* White text color for contrast */
  font-size: 12px; /* Font size */
  width: 22px; /* Circle dimensions */
  height: 22px;
  border-radius: 50%; /* Makes it circular */
padding-left: 7px;
padding-top: 1px;
  align-items: center;
  justify-content: center;
  font-weight: bold; /* Optional: Make text bold */
margin-left: 4px;

  
}

.selected-tab-assets-subtab {
  /* text-decoration: underline; */
  border-bottom: 5px solid #b1fa63;
    font-weight: bold;
    padding: 10px;
    cursor: pointer;
    margin-right: 10px;
}
.not-selected-tab-assets-subtab{
  text-decoration: none;
  cursor: pointer;
  padding: 10px;
  margin-right: 10px;
}
.catlogProductDetails-card{
  align-items: start !important;
  border: 2px solid #d0efb1; 
  border-radius: 20px;
}
.selected-assets-parent-card {
  background-color: #d0efb1;
  border-radius: 20px;
}
.file-name {
  white-space: nowrap;
  overflow: hidden; 
  text-overflow: ellipsis; 
  max-width: 420px;
  display: inline-block
}
.button-img-background-download-inprogress {
  background-color: #FD9808;
  border-radius: 10px;
}

.message-color{
  background: #B2A1FF;
  padding: 13px 40px 0px 40px;
  border-radius: 15px;
  color: #243837;
  margin-right: 30px;
  cursor: pointer;
}
.font-color-heading{
  color: #243837;
}
.message-count{
  padding: 6px 15px 6px 15px;
  background-color: white;
  border-radius: 50px;
  color: #243837;
  margin: 2px 0px 15px 0px;

}
.new-task-color{
  background: #B1FA63;
  padding: 10px 37px 10px 37px;
  color: #243837;
  border-radius: 15px;
  cursor: pointer;
  /* margin-right: 40px; */
}
.swteam-tasks{
  background: #A6FBE9;
  color: #243837;
  padding: 9px;
  border-radius: 10px;
  cursor: pointer;
}
.workflow-tasks{
  background: #D0EFB1;
  color: #243837;
  padding: 9px;
  border-radius: 10px;
  cursor: pointer;
}
.notification-tasks{
  background: #FFDDCC;
  color: #243837;
  padding: 9px;
  border-radius: 10px;
  cursor: pointer;
}
.ticket-tasks{
  background: var(--Tertiary-Purpl, #E8E2FF);
  color: #243837;
  padding: 9px;
  border-radius: 10px;
  cursor: pointer;
}
.not-seletcted-tasks{
  padding: 9px;
  border-radius: 10px;
  cursor: pointer;
}

.fixed-sidebar .app-main .app-main__outer{
  padding-left:312px;
}
.fixed-footer .app-footer .app-footer__inner{

    margin-left: 0px !important;
    box-shadow: 0.3rem -0.46875rem 2.1875rem rgba(1, 2, 3, 0.02), 0.3rem -0.9375rem 1.40625rem rgba(1, 2, 3, 0.02), 0.3rem -0.25rem 0.53125rem rgba(1, 2, 3, 0.04), 0.3rem -0.125rem 0.1875rem rgba(1, 2, 3, 0.02);
}
.ops-dash-sidebar{
  height: 755px;
  margin-top: 25px;
  margin-bottom: 25px;
  background: #b1fa63;
  border-radius: 50px;
  padding: 20px;
  align-items: center; 
  justify-content: center;
}
.item-label {
  margin-top: 10px; 
  font-size: 14px; 
  color: #333; 
  text-align: center;
}
.ops-icons {
  padding: 10px;
  background: #ffffff;
  background-color: white;
  cursor: pointer;
  border-radius: 25px;
  /* box-shadow: inset 5px 5px 5px #a7d37c, inset -5px -5px 5px #c3ef94; */
  box-shadow: 5px 5px 5px #a7d37c inset, -5px -5px 5px #c3ef94 inset;
  display: flex; 
  justify-content: center;
  width: 3.5rem;
  position: relative;
  left: 25%;
}
.ops-icons1 {
  padding: 7px;
  align-items: center;
  cursor: pointer;
  /* background: #ffffff; */
  border-radius: 25px;
  display: flex; 
  align-items: center;
  justify-content: center;
}
.custom-dropdown {
  display: block;
  position: absolute;
  z-index: 1050;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
}
.dropdown .dropdown-toggle {
  color: black; 
  border: 1px solid #ccc;
}
.task-history{
  border-style: solid;
  border-width: 0.4px;
  border-color: #b1fa63!important;
  border-radius: 10px;
  padding: 10px;
}
.issue-discription-set p:has(img) {
  height: 100px; 
  display: flex;
}
.issue-discription-set img {
  margin-left: 10px;
}
.issue-discription-set p:has(img:hover) {
  height: 100%; 
}
.display-name{
  padding: 6px;
  border-radius: 50px;
  background-color: #ffafd0;
  align-items: center;
}
.curve-popup-partner-log-chat{
  border-radius: 30px !important;
  background-color: #d0efb1!important;
}
/* Hide the image by default */
.row-hover .img-archive-icon {
  display: none;
}

/* Show the image when hovering over the row */
.row-hover:hover .img-archive-icon {
  display: inline-block;
}

.h5-with-background{
  background-size: 135px;
  background-position: center;
  background-repeat: no-repeat;
  padding: 10px;
  padding-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 188px;
  height: 10px;
  font-size: 13px;
  font-weight: bold;
  color: black;
  cursor: pointer;
  margin: 0;  
}

.h6-with-left-margin {
  margin-left: 15px;
}

.messagenum{
  font-size: 10px;                  
  font-weight: bold;                 
  color: black; 
  border: 1px solid grey;           
  border-radius: 100%;               
  padding: 3px 6px;                 
  display: inline-block;            
  text-align: center; 
  margin-left: 8px;
}

.custom-quill-editor {
  height: 40px;
  min-height: 100px;
}

.custom-card {
  margin-top: 20px; 
}

.read-more-button {
  border: none;
  background: none;
  color: blue;
  font-size: 14px;
  cursor: pointer;
  padding: 0;
  text-decoration: underline;
}

.read-more-container {
  text-align: right;
}

/* my tasks  css  */
.task-short-name {
  border: 2px solid rgb(97, 95, 95);
  border-radius: 42px;
  font-weight: 700;
  font-size: 14px;
  display: inline-block;
  text-align: center;
  max-width: 35px;
  padding: 7px 0px 1px 3px;
}
.created-at {
  margin-left: 100px;
}
.viewtaskstatus{
  border: 1px solid #6d6b6b;
  padding: 9px;
  border-radius: 10px;
}
.linetaskhistory {
  border-bottom: 1px solid #888;
  margin-bottom: 1rem;
}
.task-title-set{
  overflow-y: auto;
}
.my-task-custom-module {
  border-radius: 30px !important;
  /* border-top-right-radius: 30px!important;
  border-bottom-left-radius: 30px!important;
  border-bottom-right-radius: 30px!important; */
}
.my-task-custom-module .modal-content {
  border-radius: 30px !important;
  /* border-top-right-radius: 30px!important;
  border-bottom-left-radius: 30px!important;
  border-bottom-right-radius: 30px!important; */
}
.my-task-custom-module .modal-header {
  border-top-left-radius: 30px !important;
  border-top-right-radius: 30px !important;
}
.my-task-custom-module .modal-body {
  border-bottom-left-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}
.my-task-custom-module .modal-footer {
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}
.my-task-custom-module .modal-body {
  background-color: #A6FBE9;
}
/* Partern logs module css */
.h6-with-left-margin {
  margin-left: 15px;
}

.messagenum {
  font-size: 10px;                  
  font-weight: bold;                 
  color: white; 
  border: 1px solid grey;           
  border-radius: 50%; /* Keep it round */              
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center; 
  margin-left: 8px;
  background-color: rgb(233, 154, 8);
  width: 26px;  /* Default size */
  height: 26px;
}


.custom-quill-editor {
  height: 40px;
  min-height: 100px;
}

.custom-card {
  margin-top: 20px; 
}

.read-more-button {
  border: none;
  background: none;
  color: blue;
  font-size: 14px;
  cursor: pointer;
  padding: 0;
  text-decoration: underline;
}
.read-more-container {
  text-align: right;
}
.text-color-bolt{
  font-weight: 600px !important;
}
.row-hover-effect {
  transition: box-shadow 0.3s ease-in-out;
}
.row-hover-effect:hover {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 0px;
}
.selected-action {
  background-color: #ffdab9;
  padding: 10px 10px;
  border-radius: 10px;
  color: black;
  cursor: pointer;
}
.not-selected-action {
  background-color: transparent;
  cursor: pointer;
  padding: 4px;
}
.line-pg :hover {
display: none;
}
.bold-text {
  font-weight: bold;
  margin-top: 8px; 
  margin-bottom: 8px;
  justify-content: center;
}
.normal-text {
  font-weight: normal;
  margin-top: 8px; 
  margin-bottom: 8px;
  justify-content: center;
}
.archive{
  margin-right: 5px;
  cursor:pointer;
}
.mark-as-read {
  font-weight: bold;
  cursor:pointer;
}
.mark-as-read-icon {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;
}
.notification-item:hover .mark-as-read-icon {
  visibility: visible;
  opacity: 1;
}
.star-icon {
  transition: all 0.3s ease; 
  position: relative; 
  left: 35px;
  cursor:pointer;
}
.large-font {
  font-size: 16px; 
}
.archive-item{
  transition: all 0.3s ease; 
  position: relative; 
  right: 35px;
}
.pagination-item{
  right: 10px;
}
